const siteUrl = 'https://doeoexd6vdcrn.cloudfront.net/'

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: 'Palazzo',
    siteUrl:'https://doeoexd6vdcrn.cloudfront.net/',
    site_id: '67b61dcb26f9aad88b10e561',
    api_base_url: 'https://api.dealermasters.com:4500',
    lead_url: 'https://leads.dealermasters.com/leads',
    distance_history: 'https://aalnfl2zj7.execute-api.us-east-2.amazonaws.com/Prod', //Distance history API Gateway AWS
    googleAPIkey: 'AIzaSyCddq6ehnmTvcAdFOvG5xEGhn8jZV3eBqY',
    quickgenAPI: 'https://dev.mxsapi.com',
    imageBaseURL: 'https://images.mxssolutions.com', //PROD: 'https://images.mxssolutions.com'
    opensearchAPI: 'https://tehy4uupcb.execute-api.us-east-2.amazonaws.com/opensearch-inventory-search-api/'// DEV: 'https://q0mj4jv9i6.execute-api.us-east-2.amazonaws.com/opensearch-inventory-search-api/'
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: "gatsby-plugin-force-trailing-slashes"
    },
    {
      resolve: 'gatsby-plugin-advanced-sitemap',
      options: {
      },
    },
    {
      resolve: "gatsby-plugin-emotion"
    },
    {
      resolve: "gatsby-transformer-json"
    },
    {
      resolve: "gatsby-plugin-sass",
      options: {
        cssLoaderOptions: {
          camelCase: false,
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /\.inline\.svg$/
        }
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `src`,
        path: `${__dirname}/src/`
      }
    },
    {
      resolve: 'gatsby-plugin-s3',
      options: {
        bucketName: 'mxs-7e04250a-08ae-4f2f-8c17-cacab3e734de-prodsite'
      }
    },
    `gatsby-transformer-json`,
    {
      resolve: require.resolve('./plugins/gatsby-source-dynamodb-query'),
      options: {
        typeName: 'inventory',
        region: 'us-east-2',
        tableName: 'AdjustedInventory',
        dealerId: ['114225','260065','260066','260068']//126024
      }
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'static/favicon.png'
      }
    },{
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://doeoexd6vdcrn.cloudfront.net/',
        sitemap: 'https://doeoexd6vdcrn.cloudfront.net/sitemap-pages.xml',
        policy: [{ userAgent: '*', allow: '/' }]
      }
    }
  ]
}